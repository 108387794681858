import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaPinterestP,
} from "react-icons/fa";
import { useLocation } from "react-router-dom";

//data fetching
import useSWR from "swr";
import fetcher from "../graphql/fetcher";

const Social = () => {
  let location = useLocation();
  let isAr = location.pathname == "/ar";

  const { data, error } = useSWR(
    `{
      biographies(locales: [${isAr ? location.pathname.split("/")[1] : "en"}]) {
        contactInfo
      }
    }`,
    fetcher,
    { suspense: true }
  );
  React.useEffect(() => {}, [data, error]);
  const info = data.biographies[0]?.contactInfo;

  const SocialShare = [
    { Social: <FaFacebookF />, link: info?.facebook, label: "Facebook link" },
    { Social: <FaTwitter />, link: info?.twitter, label: "Twitter link" },
    { Social: <FaInstagram />, link: info?.insta, label: "Instagram link" },
    { Social: <FaLinkedinIn />, link: info?.linkedin, label: "Linkedin link" },
    // { Social: <FaPinterestP />, link: "https://www.pinterest.com/" },
  ];

  return (
    <div className="nav social-icons justify-content-center">
      {SocialShare.map((val, i) =>
        val.link ? (
          <>
            {/* <span className="reader-hidden">{val.label}</span> */}
            <a
              aria-label={val.label}
              key={i}
              href={`${val.link}`}
              rel="noreferrer"
              target="_blank"
            >
              <span className="reader-hidden">{val.label}</span>
              {val.Social}
            </a>
          </>
        ) : (
          ""
        )
      )}
    </div>
  );
};

export default Social;
