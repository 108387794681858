import React, { useState } from "react";
import Modal from "react-modal";
import Contact from "../contact/ContactAnimation";
import Fade from "react-reveal/Fade";
import { useLocation } from "react-router-dom";

//data fetching
import useSWR from "swr";
import fetcher from "../../graphql/fetcher";

Modal.setAppElement("#root");

const News = () => {
  const { data, error } = useSWR(
    `{
      mediaItems(orderBy: publishedOn_DESC) {
        createdBy {
          picture
        }
        author
        body {
          html
        }
        title
        updatedAt
        publishedAt
        publishedOn
        coverImage {
          url
          caption
        }
        tag
      }
    }`,
    fetcher,
    { suspense: true }
  );
  React.useEffect(() => {}, [data, error]);

  const [activeModal, setActiveModal] = useState(null);

  function showModal(e, idx) {
    setActiveModal(idx);
  }
  function hideModal() {
    setActiveModal(null);
  }

  return (
    <div className="row">
      {data?.mediaItems.map((item, idx) => {
        return (
          <React.Fragment key={idx}>
            <div className="col-md-6 m-15px-tb">
              <div className="blog-grid" onClick={(e) => showModal(e, idx)}>
                <div
                  className="blog-img reader-hidden"
                  aria-label={item.coverImage.caption}
                >
                  {item.coverImage.caption}
                  <Fade>
                    <img
                      style={{
                        objectFit: "contain",
                        width: 500,
                        height: 500,
                      }}
                      src={item.coverImage.url}
                      alt={item.coverImage.caption}
                    ></img>
                  </Fade>
                </div>
                <div className="blog-info">
                  <Fade bottom>
                    <div className="meta">
                      {new Date(item.publishedOn).toLocaleString("en-GB")}- BY{" "}
                      {item.author}
                    </div>
                    <h6>
                      <a>{item.title}</a>
                    </h6>
                  </Fade>
                </div>
              </div>
            </div>
            {/* End .col for blog-1 */}

            {/* End .row */}

            {/* Start Modal for Blog-1 */}
            <Modal
              isOpen={activeModal == idx}
              onRequestClose={hideModal}
              contentLabel="My dialog"
              className="custom-modal"
              overlayClassName="custom-overlay"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={hideModal}>
                  <img src="/img/cancel.svg" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner">
                  <div className="scrollable">
                    <div className="blog-grid">
                      <div
                        className="blog-img reader-hidden"
                        aria-label={item.coverImage.caption}
                      >
                        {item.coverImage.caption}
                        <img
                          style={{
                            objectFit: "cover",
                            width: 1000,
                            height: 230,
                          }}
                          src={item.coverImage.url}
                          alt={item.coverImage.caption}
                        ></img>
                      </div>
                      {/* End blog-img */}
                      <article className="article">
                        <div className="article-title">
                          <h2>{item.title}</h2>
                          <div className="media">
                            <div className="avatar">
                              <img
                                src={item.createdBy.picture}
                                alt="author thumbnail"
                              />
                            </div>
                            <div className="media-body">
                              <label>BY {item.author}</label>
                              <span>
                                {new Date(item.publishedOn).toLocaleString(
                                  "en-GB"
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* End .article-title */}

                        <div
                          className="article-content"
                          dangerouslySetInnerHTML={{ __html: item.body.html }}
                        ></div>
                        {/* End article content */}
                        <ul className="nav tag-cloud">
                          {item.tag.map((tag, tagIdx) => {
                            return (
                              <li key={tagIdx} href="#">
                                {tag}
                              </li>
                            );
                          })}
                        </ul>
                        {/* End tag */}
                      </article>
                      {/* End Article */}

                      {/* <div className="contact-form article-comment">
                  <h4>Leave a Reply</h4>
                  <Contact />
                </div> */}
                      {/* End .contact Form */}
                    </div>
                  </div>
                </div>
              </div>
              {/* End modal box news */}
            </Modal>
            {/* End  Modal for Blog-1 */}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default News;
