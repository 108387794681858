import React, { Suspense } from "react";
import Social from "../Social";
import Testimonials from "../testimonial/TestimonialAnimation";
import Services from "../service/ServiceAnimation";
import Awards from "../award/AwardsAnimation";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import SkeletonLoader from "../SkeletonLoader";
import { useLocation } from "react-router-dom";
import ReactMarkdown from "react-markdown";

//data fetching
import useSWR from "swr";
import fetcher from "../../graphql/fetcher";

const About = () => {
  let location = useLocation();
  let isAr = location.pathname == "/ar";

  const { data, error } = useSWR(
    `{
      biographies(locales: [${isAr ? location.pathname.split("/")[1] : "en"}]) {
        profilePicture {
          url
          caption
        }
        contactInfo
        name
        shortBio
        languages
        designations
      }
    }`,
    fetcher,
    { suspense: true }
  );
  React.useEffect(() => {}, [data, error]);

  return (
    <>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-6 col-lg-4">
            <section id="about" className="section">
              <div className="about-me">
                <div className="img">
                  <div className="img-in reader-hidden">
                    {data.biographies[0].profilePicture.caption}
                    <img
                      src={data.biographies[0].profilePicture.url}
                      alt={data.biographies[0].profilePicture.caption}
                    />
                  </div>
                  <Zoom>
                    <Social />
                  </Zoom>
                  {/* End social icon */}
                </div>
                {/* End img */}
                <div className="info">
                  <Fade bottom>
                    <p>{data.biographies[0].designations[0]}</p>
                    <h3>{data.biographies[0].name}</h3>
                  </Fade>
                </div>
                {/* End info */}
              </div>
            </section>
            {/* End about-me */}
          </div>
          {/* End col */}

          <div className="col-lg-7 ml-auto">
            <div className="about-info">
              <div className="title">
                <h3>{isAr ? "نبذة عن فاطمة" : "A little bit about Fatma"}</h3>
              </div>
              <div className="about-text">
                <Fade bottom>
                  <ReactMarkdown>{data.biographies[0].shortBio}</ReactMarkdown>
                </Fade>
              </div>
              {/* <div className="info-list">
                <Fade bottom>
                  <div className="row">
                    <div className="col-sm-6">
                      <ul>
                        <li>
                          <label>{isAr ? "اسم" : "Name"}: </label>
                          <span>{data.biographies[0].name}</span>
                        </li>
                        <li>
                          <label>{isAr ? "وظيفة" : "Role"}: </label>
                          <span>
                            {data.biographies[0]?.designations[0] ||
                              "Accessibility Professional"}
                          </span>
                        </li>
                        <li>
                          <label>{isAr ? "اللغات" : "Languages"}: </label>
                          <span>
                            {data.biographies[0]?.languages || "En,Ar"}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-6">
                      <ul>
                        <li>
                          <label>{isAr ? "هاتف" : "Phone"}: </label>
                          <span>{data.biographies[0].contactInfo?.phone}</span>
                        </li>
                        <li>
                          <label>{isAr ? "بريد الالكتروني" : "Email"} </label>
                          <span>{data.biographies[0].contactInfo?.email}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Fade>
              </div> */}
            </div>
          </div>
          {/* End col */}
        </div>

        {/* separated */}

        <div
          className="separated"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "img/border-dark.png"
            })`,
          }}
        ></div>

        {/* End separated */}
        <section id="services" className="section">
          <div className="title">
            <h3>{isAr ? "الخدمات" : "Services"}</h3>
          </div>
          {/* <Suspense fallback={<SkeletonLoader />}> */}
          <Services />
          {/* </Suspense> */}
        </section>
        {/* End .row */}

        {/* separated */}
        <div
          className="separated"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "img/border-dark.png"
            })`,
          }}
        ></div>
        {/* End separated */}

        <div className="title">
          <h3>{isAr ? "الإنجازات" : "Accomplishments"}</h3>
        </div>

        <Awards />
        {/* End Awards */}

        {/* separated */}
        <div
          className="separated"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "img/border-dark.png"
            })`,
          }}
        ></div>
        {/* End separated */}

        <section id="testimonials" className="section">
          <div className="title">
            <h3>{isAr ? "ماذا قالوا عنا" : "Testimonials"}</h3>
          </div>
        </section>

        <Testimonials />
        {/* End Testimonaial */}
      </div>
    </>
  );
};

export default About;
