import React from "react";
import { useForm } from "react-hook-form";
import Fade from "react-reveal/Fade";
import { useLocation } from "react-router-dom";
import { GraphQLClient, gql } from "graphql-request";

const graphQLClient = new GraphQLClient(process.env.REACT_APP_GRAPHQL_API);
const SUBMIT_MUTATION = gql`
  mutation createFormSubmission(
    $email: String!
    $formType: FormType!
    $submissionEntry: Json!
  ) {
    createFormSubmission(
      data: {
        email: $email
        formType: $formType
        submissionEntry: $submissionEntry
      }
    ) {
      email
      formType
      submissionEntry
      id
    }
  }
`;

const ContactRtl = () => {
  const [sent, setSent] = React.useState(false);
  let location = useLocation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data, e) => {
    setSent(false);
    e.target.reset();
    reset();
    // console.log("Message submited: " + JSON.stringify(data));
    const variables = {
      email: data.email,
      formType: "contact",
      submissionEntry: {
        name: data.name,
        email: data.email,
        subject: data.subject,
        comment: data.comment,
      },
    };
    const responseData = await graphQLClient.request(
      SUBMIT_MUTATION,
      variables
    );
    if (responseData) {
      setSent(true);
      // console.log(responseData);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <Fade bottom>
              <div className="form-group mb-3">
                <input
                  type="text"
                  className="form-control theme-light"
                  placeholder="الاسم الكامل"
                  {...register("name", { required: true })}
                />
                {errors.name && errors.name.type === "required" && (
                  <span className="invalid-feedback">مطلوب اسم</span>
                )}
              </div>
            </Fade>
          </div>
          {/* End .col-6 */}

          <div className="col-md-6">
            <Fade bottom>
              <div className="form-group mb-3">
                <input
                  type="email"
                  className="form-control theme-light"
                  placeholder="عنوان البريد الإلكتروني"
                  {...register(
                    "email",
                    {
                      required: "البريد الالكتروني مطلوب",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message:
                          "القيمة التي تم إدخالها لا تطابق تنسيق البريد الإلكتروني",
                      },
                    },
                    { required: true }
                  )}
                />
                {errors.email && (
                  <span className="invalid-feedback">
                    {errors.email.message}
                  </span>
                )}
              </div>
            </Fade>
          </div>
          {/* End .col-6 */}

          <div className="col-12">
            <Fade bottom>
              <div className="form-group mb-3">
                <select
                  className="form-control theme-light"
                  {...register("subject", { required: true })}
                >
                  <option active value="">
                    -اختر الموضوع-
                  </option>
                  <option value="consultation">استشارة</option>
                  <option value="speaking">محاضرات</option>
                  <option value="training">تدريب</option>
                  <option value="other">استفسارات أخرى</option>
                </select>
                {errors.subject && (
                  <span className="invalid-feedback">الموضوع مطلوب.</span>
                )}
              </div>
            </Fade>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <Fade bottom>
              <div className="form-group mb-3">
                <textarea
                  rows="4"
                  className="form-control theme-light"
                  placeholder="اكتب تعليق"
                  {...register("comment", { required: true })}
                ></textarea>
                {errors.comment && (
                  <span className="invalid-feedback">التعليق مطلوب.</span>
                )}
              </div>
            </Fade>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <Fade bottom>
              <div className="btn-bar">
                <button className="px-btn px-btn-white">أرسل رسالة</button>
              </div>
            </Fade>
          </div>
          <p style={{ fontSize: 20, color: "purple" }}>
            {sent ? "تم إرسال البيانات. شكرا !" : ""}
          </p>
          {/* End .col-12 */}
        </div>
      </form>
    </>
  );
};

export default ContactRtl;
