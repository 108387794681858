import React from "react";
import TextLoop from "react-text-loop";
import Fade from "react-reveal/Fade";
import { useLocation } from "react-router-dom";
import ReactMarkdown from "react-markdown";

//data fetching
import useSWR from "swr";
import fetcher from "../../graphql/fetcher";

// const conctInfo = {
//   phone: "+04 6545-9535-6515",
//   email: "",
// };

// const sliderContent = {
//   name: "Fatma Al Jassim",
//   designation: "Full-stack Developer",
//   description: `I design and develop services for customers of all sizes,
//   specializing in creating stylish, modern websites, web
//   services and online stores.`,
//   btnText: "Download CV",
// };

const Slider = () => {
  const { data, error } = useSWR(
    `{
      biographies {
        profilePicture {
          url
          caption
        } 
        headerImage {
          url
          caption
        }
        contactInfo
        name
        shortBio
        designations
        description
      }
    }`,
    fetcher,
    { suspense: true }
  );
  React.useEffect(() => {}, [data, error]);
  let location = useLocation();
  return (
    <>
      {/*  Home Banner */}
      <section id="home" className="home-banner">
        <div className="hb-top-fixed d-flex">
          <div className="hb-info">
            <a href={`tel:${data.biographies[0].contactInfo.phone}`}>
              {data.biographies[0].contactInfo.phone}
            </a>
            <a href={`mailto:${data.biographies[0].contactInfo.email}`}>
              {data.biographies[0].contactInfo.email}
            </a>
          </div>
          <div className="hb-lang">
            <ul className="nav">
              <li className={location.pathname == "/ar" ? "" : "active"}>
                <a href="/">EN</a>
              </li>
              <li className={location.pathname == "/ar" ? "active" : ""}>
                <a href="/ar">AR</a>
              </li>
            </ul>
          </div>
        </div>
        {/* End hp-top-fixed */}

        <div className="container">
          <div className="row full-screen align-items-center">
            <div className="col-lg-7">
              <div className="type-box">
                <Fade bottom>
                  <h4>Meet</h4>
                  <h1 className="font-alt">{data.biographies[0].name}</h1>
                  <TextLoop>
                    {data.biographies[0].designations.map((item, index) => {
                      return (
                        <p key={index} className="loop-text lead">
                          {item}
                        </p>
                      );
                    })}
                  </TextLoop>{" "}
                  <ReactMarkdown>
                    {data.biographies[0]?.description}
                  </ReactMarkdown>
                  {/* <div className="mt-4">
                    <a
                      className="px-btn px-btn-white"
                      href="img/resume.png"
                      download
                    >
                      {sliderContent.btnText}
                    </a>
                  </div> */}
                </Fade>
              </div>
            </div>
          </div>
        </div>
        {/* End Container*/}
        <div
          className="hb-me reader-hidden "
          title={data.biographies[0].headerImage.caption}
          aria-label={data.biographies[0].headerImage.caption}
          style={{
            backgroundImage: `url(${data.biographies[0].headerImage.url})`,
          }}
        >
          {data.biographies[0].headerImage.caption}
        </div>
      </section>

      {/* End Home Banner  */}
    </>
  );
};

export default Slider;
