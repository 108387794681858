import React from "react";
import Fade from "react-reveal/Fade";
import { useLocation } from "react-router-dom";

//data fetching
import useSWR from "swr";
import fetcher from "../../graphql/fetcher";

export default function Service() {
  let location = useLocation();
  let isAr = location.pathname == "/ar";

  const { data, error } = useSWR(
    `{
      services(locales: [${isAr ? location.pathname.split("/")[1] : "en"}]) {
        title
        description
        icon
      }
    }`,
    fetcher,
    { suspense: true }
  );
  React.useEffect(() => {}, [data, error]);

  const ServiceContent = data?.services.map((item) => {
    return {
      icon: item.icon,
      title: item.title,
      descriptions: item.description,
    };
  });

  return (
    <>
      <div className="row">
        {ServiceContent.map((val, i) => (
          <div className="col-md-6 col-lg-4 my-3" key={i}>
            <div className="feature-box-01">
              <Fade bottom>
                <div className="icon">
                  <i
                    aria-label={"arrow with target icon"}
                    className={`icon ${val.icon}`}
                  ></i>
                </div>
                <div aria-label={"service content"} className="feature-content">
                  <h5>{val.title}</h5>
                  <p>{val.descriptions}</p>
                </div>
              </Fade>
            </div>
            {/* End .feature-box-01 */}
          </div>
        ))}
      </div>
    </>
  );
}
