import React from "react";
import Fade from "react-reveal/Fade";
import { useLocation } from "react-router-dom";

//data fetching
import useSWR from "swr";
import fetcher from "../../graphql/fetcher";

const Awards = () => {
  let location = useLocation();
  let isAr = location.pathname == "/ar";

  const { data, error } = useSWR(
    `{
      awards(locales: [${isAr ? location.pathname.split("/")[1] : "en"}]) {
        awardFor
        awardName
        awardImage {
          url
          caption
        }
      }
    }`,
    fetcher,
    { suspense: true }
  );
  React.useEffect(() => {}, [data, error]);

  const AwardContnet = data.awards.map((item) => {
    return {
      img: item.awardImage.url,
      awardName: item.awardName,
      awardFor: item.awardFor,
      caption: item.awardImage.caption,
    };
  });
  return (
    <>
      <div className="row">
        {AwardContnet.map((val, i) => (
          <div className="col-lg-4 m-15px-tb" key={i}>
            <div className="feature-box-02 d-flex align-items-center">
              <Fade bottom>
                <div className="icon reader-hidden" aria-label={val.caption}>
                  {val.caption}
                  <img alt={val.caption} src={val.img} />
                </div>
                <div className="media-body">
                  <h6>{val.awardName}</h6>
                  <p>{val.awardFor}</p>
                </div>
              </Fade>
            </div>
          </div>
          // End .col
        ))}
      </div>
      {/* End .row */}
    </>
  );
};

export default Awards;
