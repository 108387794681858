import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Fade from "react-reveal/Fade";
import { useLocation } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa";

//data fetching
import useSWR from "swr";
import fetcher from "../../graphql/fetcher";

export default function SimpleSlider() {
  let location = useLocation();
  let isAr = location.pathname == "/ar";

  const { data, error } = useSWR(
    `{
      testimonials(locales: [${
        isAr ? location.pathname.split("/")[1] : "en"
      }]) {
        name
        linkedinUrl
        content
        designation
        rating
        coverImage {
          caption
          url
        }
        date
      }
    }`,
    fetcher,
    { suspense: true }
  );
  React.useEffect(() => {}, [data, error]);

  const settings = {
    dots: true,
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    margin: 30,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const TestimonilContent = data?.testimonials.map((item) => {
    return {
      imageName: item.coverImage.url,
      imageCaption: item.coverImage.caption,
      desc: item.content,
      reviewerName: item.name,
      designation: item.designation,
      rating: item.rating,
      linkedinUrl: item.linkedinUrl,
    };
  });

  return (
    <div className="testimonial-wrapper">
      <Slider {...settings}>
        {TestimonilContent.map((val, i) => (
          <div key={i}>
            <div className="testimonial-01 media">
              <Fade bottom>
                <div className="avatar" aria-label={val.imageCaption}>
                  <p className="reader-hidden">
                    {val.imageCaption}
                    <img src={val.imageName} alt={val.imageCaption} />
                  </p>
                </div>
                <div className="media-body">
                  <p>"{val.desc}"</p>
                  {/* <span>{val.rating}/5</span> */}
                  <h6>{val.reviewerName}</h6>
                  <span>{val.designation}</span>
                  <a
                    style={{ marginLeft: 10 }}
                    href={val.linkedinUrl}
                    target="_blank"
                  >
                    <FaLinkedin style={{ fontSize: 20 }} />
                  </a>
                </div>
              </Fade>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
