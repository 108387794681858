import React, { useState } from "react";
import Scrollspy from "react-scrollspy";
import { Link, useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {
  FiUser,
  FiBriefcase,
  FiFileText,
  FiPhoneOutgoing,
  FiStar,
} from "react-icons/fi";
import { FaHome, FaBlog } from "react-icons/fa";

//data fetching
import useSWR from "swr";
import fetcher from "../../graphql/fetcher";

const Header = () => {
  let location = useLocation();
  let isAr = location.pathname == "/ar";

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const { data, error } = useSWR(
    `{
      biographies(locales: [${isAr ? location.pathname.split("/")[1] : "en"}]) {
        headerImage {
          url
          caption
        }
      }
    }`,
    fetcher,
    { suspense: true }
  );
  React.useEffect(() => {}, [data, error]);

  return (
    <>
      {/* Header */}
      <div className="mob-header">
        <button className="toggler-menu" onClick={handleClick}>
          <div className={click ? "active" : ""}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
      </div>
      {/* End Header */}

      {/* nav bar */}
      <header className={click ? "header-left menu-open" : "header-left "}>
        <div className="scroll-bar">
          <div className="hl-top">
            <div className="hl-logo reader-hidden">
              {data.biographies[0]?.headerImage.caption}
              <img
                src={data.biographies[0]?.headerImage.url}
                aria-label={data.biographies[0]?.headerImage.caption}
                alt={data.biographies[0]?.headerImage.caption}
              />

              {/* <a href="#home">{location.pathname == "/ar" ? "ف" : "F"}</a> */}
            </div>
          </div>
          {/* End htl-top */}

          <Scrollspy
            style={{ marginBottom: "50vh" }}
            className="nav nav-menu"
            items={[
              "home",
              "about",
              "services",
              "testimonials",
              "work",
              "blog",
              "contactus",
            ]}
            currentClassName="active"
            offset={-30}
          >
            <li>
              <a
                className="nav-link "
                href="#home"
                data-tip
                data-for="HOME"
                onClick={handleClick}
              >
                <FaHome />
                <ReactTooltip id="HOME" place="top" type="dark" effect="float">
                  <span>{isAr ? "الصفحة الرئيسية" : "Home"}</span>
                </ReactTooltip>
              </a>
            </li>
            <li>
              <a
                className="nav-link"
                href="#about"
                data-tip
                data-for="ABOUT"
                onClick={handleClick}
              >
                <FiUser />
                <ReactTooltip id="ABOUT" place="top" type="dark" effect="float">
                  <span>{isAr ? "عن" : "About"}</span>
                </ReactTooltip>
              </a>
            </li>
            <li>
              <a
                className="nav-link"
                href="#services"
                data-tip
                data-for="SERVICES"
                onClick={handleClick}
              >
                <FiFileText />
                <ReactTooltip
                  id="SERVICES"
                  place="top"
                  type="dark"
                  effect="float"
                >
                  <span>{isAr ? "خدمات" : "Services"}</span>
                </ReactTooltip>
              </a>
            </li>
            <li>
              <a
                className="nav-link"
                href="#testimonials"
                data-tip
                data-for="TESTIMONIALS"
                onClick={handleClick}
              >
                <FiStar />
                <ReactTooltip
                  id="TESTIMONIALS"
                  place="top"
                  type="dark"
                  effect="float"
                >
                  <span>{isAr ? "التوصيات" : "Testimonials"}</span>
                </ReactTooltip>
              </a>
            </li>
            <li>
              <a
                className="nav-link"
                href="#work"
                data-tip
                data-for="WORK"
                onClick={handleClick}
              >
                <FiBriefcase />
                <ReactTooltip id="WORK" place="top" type="dark" effect="float">
                  <span>{isAr ? "عمل" : "Work"}</span>
                </ReactTooltip>
              </a>
            </li>
            <li>
              <a
                className="nav-link"
                href="#blog"
                data-tip
                data-for="BLOG"
                onClick={handleClick}
              >
                <FaBlog />
                <ReactTooltip id="BLOG" place="top" type="dark" effect="float">
                  <span>{isAr ? "إعلام" : "Media"}</span>
                </ReactTooltip>
              </a>
            </li>
            <li>
              <a
                className="nav-link"
                href="#contactus"
                data-tip
                data-for="CONTACT"
                onClick={handleClick}
              >
                <FiPhoneOutgoing />
                <ReactTooltip
                  id="CONTACT"
                  place="top"
                  type="dark"
                  effect="float"
                >
                  <span>{isAr ? "اتصل" : "Contact"}</span>
                </ReactTooltip>
              </a>
            </li>
          </Scrollspy>
        </div>
      </header>
      {/* End Header */}
    </>
  );
};

export default Header;
