import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import { FiGrid, FiPlus } from "react-icons/fi";
import Masonry from "react-masonry-css";
import Fade from "react-reveal/Fade";
import PFGridItem from "./PFGridItem";
import { useLocation } from "react-router-dom";

//data fetching
import useSWR from "swr";
import fetcher from "../../graphql/fetcher";

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  700: 2,
  500: 1,
};

const Portfolio = () => {
  let location = useLocation();
  let isAr = location.pathname == "/ar";

  const { data, error } = useSWR(
    `{
      portfolios(locales: [${isAr ? location.pathname.split("/")[1] : "en"}]) {
        title
        description
        coverImage {
          caption
          url
        }
        pTypes(locales: [${isAr ? location.pathname.split("/")[1] : "en"}]) {
          name
        }
      }
    }`,
    fetcher,
    { suspense: true }
  );
  React.useEffect(() => {}, [data, error]);
  const unique = data && [
    ...new Set(
      data.portfolios.map((item) => item.pTypes.map((type) => type.name))
    ),
  ];
  // console.log(unique);
  const types = unique && [...new Set(unique.flat(1))];
  // console.log(types);

  return (
    <SimpleReactLightbox>
      <div className="portfolio-filter-01">
        <Tabs>
          <TabList className="filter d-flex flex-wrap justify-content-start">
            <Tab>{isAr ? "الجميع" : "All"}</Tab>
            {types.map((type, index) => {
              return <Tab key={index}>{type}</Tab>;
            })}
          </TabList>
          {/* End tablist */}

          <SRLWrapper>
            <TabPanel>
              <div className="portfolio-content ">
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  {data.portfolios.map((item, index) => {
                    return item && <PFGridItem key={index} item={item} />;
                  })}
                </Masonry>
              </div>
              {/* End list wrapper */}
            </TabPanel>

            {types.map((type, index) => {
              return (
                <TabPanel key={index}>
                  <div className="portfolio-content">
                    <Masonry
                      breakpointCols={breakpointColumnsObj}
                      className="my-masonry-grid"
                      columnClassName="my-masonry-grid_column"
                    >
                      {data.portfolios.map((item, index) => {
                        console.log(item.pTypes.some((t) => t.name === type));
                        if (item && item.pTypes.some((t) => t.name === type)) {
                          return <PFGridItem key={index} item={item} />;
                        }
                      })}
                    </Masonry>
                  </div>
                  {/* End list wrapper */}
                </TabPanel>
              );
            })}
          </SRLWrapper>
          {/* End tabpanel */}
        </Tabs>
      </div>
    </SimpleReactLightbox>
  );
};

export default Portfolio;
