import React from "react";
import Fade from "react-reveal/Fade";
import { useLocation } from "react-router-dom";

//data fetching
import useSWR from "swr";
import fetcher from "../../graphql/fetcher";

const ContactInfo = () => {
  let location = useLocation();
  let isAr = location.pathname == "/ar";

  const { data, error } = useSWR(
    `{
      biographies(locales: [${isAr ? location.pathname.split("/")[1] : "en"}]) {
        contactInfo
      }
    }`,
    fetcher,
    { suspense: true }
  );
  React.useEffect(() => {}, [data, error]);

  return (
    <>
      <div className="contact-info">
        <Fade bottom>
          <h4>{isAr ? "تواصلوا معي" : "Get in touch"}</h4>
          {/* <p>{isAr ? "لا تتردد في الاتصال بي." : "Feel free to contact me."}</p> */}
        </Fade>
        <ul>
          <Fade bottom>
            {data.biographies[0].contactInfo?.email ? (
              <li className="media">
                <i className="icon icon-envelope"></i>
                <span className="media-body">
                  {data.biographies[0].contactInfo?.email}
                </span>
              </li>
            ) : (
              ""
            )}

            {data.biographies[0].contactInfo?.phone ? (
              <li className="media">
                <i className="icon icon-phone"></i>
                <span className="media-body">
                  {data.biographies[0].contactInfo?.phone}
                </span>
              </li>
            ) : (
              ""
            )}
          </Fade>
        </ul>
      </div>
      {/* End .contact-info */}
    </>
  );
};

export default ContactInfo;
