import React from "react";
import { FiGrid, FiPlus } from "react-icons/fi";

export default function PFGridItem({ item }) {
  // console.log(item);
  return (
    <div>
      <div className="portfolio-box-01">
        <div className="portfolio-img">
          <div className="portfolio-info">
            <h5>{item.title}</h5>
            <span>{item.description}</span>
          </div>
          {/* End .portfolio-info */}
          <a
            href={item?.coverImage?.url}
            className="gallery-link reader-hidden"
            aria-label={item?.coverImage?.caption}
          >
            {item?.coverImage?.caption}
            <img
              style={{ objectFit: "cover", width: 500, height: 350 }}
              src={item?.coverImage?.url}
              alt={item?.coverImage?.caption}
            />
          </a>
          {/* End gallery link */}

          <div className="portfolio-icon">
            <FiPlus />
          </div>
          {/* End .portfolio-icon */}
        </div>
      </div>
    </div>
  );
}
