import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function SkeletonLoader() {
  return (
    <div>
      <SkeletonTheme color="#f7f7ff" highlightColor="#5b52a3">
        <p>
          <Skeleton count={1} />
        </p>
      </SkeletonTheme>
      ;
    </div>
  );
}
